.student-record {
  
  .section-form {
    margin-bottom: 20p  x;
    padding: 20px; 

    .new-section-form {
      display: flex;
      gap: 10px;  
      padding: 10px;
    }
  }

  .sections-container {
    justify-content: center;
    padding: 20px;

    .sections-row {
      display: flex; 
      overflow: hidden;
      overflow-x: auto;
      gap: 10px; 
      padding: 5px; 
    }
    
    .section-item {
      flex: 0 1 auto; 
      max-width: 120px;
      position: relative;
      
      .section-btn {
        position: relative;
        padding: 8px 40px;
        transition: 0.3s ease-in-out; 
      
        &.selected {
          font-size: 120%;
          font-weight: bold;
          padding: 8px 40px;
          background-color: var(--btn-hov);
        }   
    
        .remove-btn {
          position: absolute;
          top: 0px;
          right: 0px; 
          min-width: 30px;
          max-width: 10px;
          color: white; 
          background-color: var(--del-btn);
          border-radius: 3px;
          padding: 3px 5px;
          border: none; 
          cursor: pointer;
          z-index: 10000;
        
          &hover {
            background-color: var(--del-hov);
          }
        }
      }
    }
  }


  .student-form,
  .edit-form {
    padding-top: 20px;

    .student-form-inner,
    .edit-form-inner {
      margin: 0 auto;
      max-width: 500px;
      text-align: center;
      align-items: center;
      justify-content: center;
      gap: 10px;
      
      .user-form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 0;
        border-bottom: 1px solid #ddd;
      }

      .reg-div {
        display: flex ;
        margin-top: 10px;
        gap: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 10px;
      }
    } 
  }

  .student-rec-log {
    /* padding: 20px; */

    .student-table {
      overflow: hidden;
      overflow-x: auto;
      overflow-y: auto;
      max-height: 200px;
    }
  }
  @media screen and (max-width: 1025px) {
    .available-list {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  @media screen and (max-width: 426px) {
    button,
    select {
      font-size: 90%;
      padding: 5px;
    }

    .student-table {
      display: flex;

      .action {
        display: flex;
        gap: 3px;

        button, i {
          font-size: 90%;
          min-width: 3px;
        }
      }
    }

    .available-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .available-list {
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: center;
    
    .student-rfid-list {
      width: 90%;
    }
    .student-pc-list {
      width: 90%;

      h3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: justify;
        gap: 10px;
        
        .bind-opt-cont {
          display: flex;
          gap: 5px;
          
          a {
            border: var(--btn) 1px solid;
            border-radius: 5px;
            padding: 0 10px;

            &:hover {
              border-color: var(--btn-hov);
              color: var(--btn-hov);
            }
          }
        }
      }
    }
  }

  .available-list {
    button {
      padding: 10px 5px;
    }

    .rfid-cont,
    .pc-cont{
      display: grid;
      /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
      text-align: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      overflow: hidden;
      overflow-y: auto;
      max-height: 200px;
      gap: 10px;

      label {
        margin-right: 8px;
        font-weight: bold;
      }
      
      button {
        margin-left: 8px;
      }
    }
    
  }

  .cont-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    .bind-btn-opt {
      color: var(--btn);
      transition: .3s ease-in-out;
    
      &:hover {
        color: var(--btn-hov);
      }
    }
  }

  .avail-list {
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
    max-height: 200px;
    white-space: nowrap;

    .rfid-item,
    .pc-item {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      max-width: fit-content;
      text-align: center;
      justify-content: start;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
    }

    .rfid-name,
    .pc-name {
      margin-right: 10px;
      font-weight: bold;
      min-width: 100px;
    }

    button {
      padding: 5px 8px;
    }

    select {
      padding: 6px 8px;
    }
  }
}