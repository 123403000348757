.logbook {
    
  .log-table {
    border-collapse: collapse;
    justify-content: center;
    align-items: center;
    
    .log-table-cont {
      margin-top: -10px;
      overflow: hidden;
      overflow-x: auto;
      overflow-y: auto;

      .action {
        display: flex;
        justify-content: center;
        }
      }
  } 
  
  .pagination-controls {
    display: flex;
    align-items: center; 
    justify-content: center;
    text-align: center;
    gap: 4px;
    margin-top: 20px;
    background-color: transparent;


    .page-btn {
      background-color: var(--bg1);
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
      max-width: fit-content;
      min-width: fit-content;

      &:hover:not(:disabled) {
        background-color: var(--bg2); 
      }

      &:disabled {
        background-color: #ccc; 
        cursor: not-allowed; 
      }
    }
  
    .prev-btn,
    .next-btn {
      background-color: transparent;
      border: 1px solid var(--main-txt);
      color: var(--main-txt);
      
      &:hover:not(:disabled) {
        background-color: transparent; 
      }

      &:disabled {
        background-color: transparent; 
        cursor: not-allowed; 
      }
    }
  }
    
  @media (min-width: 426px) {
      .act-btn {
        display: block;
      }

      .action-filter {
        display: none;
      }
  }

  @media (max-width: 426px) {
    .filter-controls {
      flex-direction: column;
      align-items: stretch;
    }

    .act-btn {
      display: none;
    }

    .action-filter {
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    input,
    select {
      width: 100%; 
      box-sizing: border-box;
    }

    .gen-report {
      justify-content: center;
    }
  }
}
  