.class-schedule {
  .no-active-sem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
  }
  .add-sem-form {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
  }

    .sched-table {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .hr-min .flex-cont {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 5px;
    }

    .hr-min input {
        width: 50px;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .day {
        padding: 10px 50px;
    }

    .add-row {
        text-align: center;
    }

    .add-sched-btn {
        background-color: transparent;
        color: white;
        border: 2px solid white;
        transition: 0.2s ease-in-out;
        padding: 10px 13px;
    }

    .add-sched-btn:hover {
        background-color: transparent;
        border-color: var(--bg1);
        color: var(--bg1);
    }

    .edit-subj-form,
    .subj-form {
      padding: 20px;
      
      .edit-subj-form-inner,
      .subj-form-inner {
        margin: 0 auto;
        max-width: 500px;
        text-align: center;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 10px;
        
        .user-form {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: center;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5em 0;
          border-bottom: 1px solid #ddd;
        }
  
        .reg-div {
          display: flex ;
          margin-top: 10px;
          gap: 10px;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-bottom: 10px;
        }
      } 
    }

  .subj-table {
    border-collapse: collapse;
    justify-content: center;
    align-items: center;

    .table-div {
        overflow: hidden;
        overflow-x: auto;
        overflow-y: auto;
    }
    .action {
        padding: 5px 0;
        display: flex;
        justify-content: center;
    }
  }      

  .end-sem-btn {
    display: flex;
    justify-content: flex-end;
  }
}

@media (min-width: 426px) {
    .subj-table {
        display: block;
    }

    .mobile-subj-table {
        display: none;
    }
}

@media (max-width: 426px) {
    .subj-table {
      display: none; 
    }
  
    .mobile-subj-table {
      display: block; 
      padding: 10px;
      border: 1px solid #ddd;
      margin: 10px 0; 
    }
  
    .mobile-subj-table .subject-name {
      font-weight: bold;
      margin-bottom: 10px; 
    }
  
    .day-time-row,
    .section-faculty-row,
    .action-row {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
  
    .day-time-row div,
    .section-faculty-row div,
    .action-row button {
      flex: 1; 
      text-align: center; 
    }
  
    .action-row {
      margin-top: 20px;
    }
  
    .action-row button {
      margin: 0 5px;
    }
}
  
