:root {
  /* bg colors */
  --bg1: #ffffff;
  /* --bg2: #b5fbb6;  */
  --bg2: #e0f2ff;
  --bg3: #75bdc3;
  --bg4: #f3c41c;
  --bg5: #edf3f0;
  --bg6: #75bdc3;
  --btn: #007bff; /* add, edit, and update btn */
  --btn-hov: #0056b3; /* hover */
  --del-btn: #e52929; /* also for remove */
  --del-hov: #821717; /* hover */
  --main-txt: #0c0c0c;
  --h1-txt: #121212;
  --h2-txt: #121212;
  --sub-txt: #292929;
  --txt2: #4fa5ad;
  --txt3: #11b579;
  --alert-bg: rgba(0, 0, 0, 0.5);
  --div-hov: #dadada;
}

body {
  margin: 0;
  padding: 0;
  font-size: 98%;
  width: 100%;
  color: black;
  overflow: hidden;
}

.ss-icon {
  background-image: url('../Assets/ss_icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px; 
  height: 50px;
}

/* Universal btn */
button {
  flex: 1 1 auto;
  min-width: 70px;
  max-width: fit-content;
  padding: 5px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: var(--btn);
  color: white;
  cursor: pointer;
  transition: 0.3s ease-in-out;
            
  &:hover {
    background-color: var(--btn-hov);
  }

  &:disabled {
    background-color: #ccc; 
    cursor: not-allowed; 
  }
}

.del-btn {
  background-color: transparent;
  border: 2px solid var(--del-btn);
  color: var(--del-btn);
  transition: 0.3s ease-in-out;
  
  &:hover {
    color: var(--del-hov);
    border: 2px solid var(--del-hov);
    background-color: transparent;
  }
  
  &:disabled {
    background-color: transparent;
    color: var(--del-hov); 
    border: 2px solid var(--del-hov); 
    cursor: not-allowed; 
  }
}

/* Universal input */
select,
input {
  padding: 6px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  align-items: center;
}

/* Universal input & seePassword */
.password-input {
  position: relative;
  display: inline-block;

  input {
    padding-right: 0.5rem; 
    width: 100%;
    box-sizing: border-box;
  }

  .see-pass-btn {
    position: absolute;
    top: 50%;
    right: -1rem;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: var(--btn);
    padding: 0;
    transition: .3s ease-in-out;

    &:hover {
      color: var(--btn-hov);
    }

    .fa-eye,
    .fa-eye-slash {
      font-size: .8rem; /* Adjust icon size */
    }
  }
}

/* Universal cont */
.cont {
  position: relative;
  margin-bottom: 35px;
  gap: 5px;
  background: white;
  border-radius: 8px;
  box-shadow: 2px 6px 30px rgba(1, 32, 124, 0.1);
  padding: 35px 15px 25px 15px;

  h3 {
    display: flex;
    position: absolute;
    top: -1.6rem;
    left: 1.5rem; 
    padding: 0 10px;
    background: transparent;
    font-weight: bold;
    color: var(--h2-txt);
    font-size: 120%;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  }
}

/* Universal filter row */
.filter-controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  align-items: stretch;
  
  .filter-div {
    overflow: hidden;
    overflow-y: auto;
  }

  .filter-cont {
    position: relative;
    margin-top: 10px;
    
    label {
      position: absolute;
      top: -0.5rem;
      left: .5rem; 
      padding: 0 10px;
      background: var(--bg1);
      font-weight: bold;
      color: var(--h2-txt);
      font-size: 90%;
      width: fit-content;
    }
  }

  .filter-btn {
    display: flex;
    gap: 10px;
    margin-top: 9px;
  }
}

/* Universal download pdf report*/
.gen-report {
  display: flex;
  gap: 10px;
  padding-top: 20px;
  justify-content: right;
  align-items: center;
  text-align: center;

  select {
    border: none;
    text-decoration: none;
    width: fit-content;
  }
  
  button {
    background-color: transparent;
    border: var(--btn) 2px solid;
    color: var(--btn);
    padding: 6px 10px;
    transition: .3s ease-in-out;

    &:hover{
      color: var(--btn-hov);
      border: var(--btn-hov) 2px solid;
    }
  }
}  

/* Universal table */
table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 5px;
    text-align: center;
    border: 1px solid #ddd;
    min-width: fit-content;
    white-space: nowrap;
  }

  th {
    background-color: #f2f2f2;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
}

/* Universal for no fetch details */
.no-fetch-msg {
  font-size: 120%;
  font-weight: bold;
  padding: 4px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--btn);
}

/* Universal file input */
.adding-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .adding-btn-section,
  .adding-file-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .file-batch-input {
    margin: 0 10px;
    max-width: 180px;
  }

  .add-section-btn {
    display: inline-block;
    padding: 8px 23px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      background-color: var(--btn-hov);
    }
  }

  /* Responsive styles */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch; /* Make elements full-width on smaller screens */

    .adding-btn-section,
    .adding-file-section {
      width: 100%;
      justify-content: center;
      margin-bottom: 10px;
    }

    .file-batch-input {
      width: 100%;
      max-width: 100;
      margin: 5px;
    }

    .add-section-btn {
      width: 100%;
      text-align: center;
      padding: 10px;
    }
  }
}

.dashboard {
  height: 100vh;
  background-color: var(--bg1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: var(--bg2);
  /* background: linear-gradient(90deg, var(--bg1) 0%, var(--bg2) 80%); */
  background: linear-gradient(180deg, var(--bg1) 30%, var(--bg2) 90%);

}

.mid-head {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  padding: -20px;
  
  .ss-icon {
    background-image: url('../Assets/ss_icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px; 
    height: 50px;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--h1-txt);
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

    span {
      /* font-style: italic; */
      color: var(--txt2);
    }
  }
}

.right-head {
  display: flex;
  align-items: center;
}

.right-head .prof-icon {
  margin-right: 0.5rem;
}

.prof-icon {
  transition: .3s ease-in-out;
  font-weight: bold;

  &:hover {
    color: var(--btn-hov);
  }
}

.right-head .prof-name {
  margin-right: 1rem;
}

/* Dashboard panels layout */
.dashboard-panels-wrapper {
  display: flex;
  flex: 1;
  height: calc(100vh - 60px);
  overflow: hidden;
}

.side-panel-L {
  width: 100%;
  max-width: 200px;
  padding: 10px;
  overflow-y: auto;
  transition: width 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 22px;
}

.content-panel {
  flex: 1;
  padding: 20px;
  background-color: var(--bg1);
  overflow-y: auto;
  /* border-left: solid 2px var(--bg3); */
}

/* Right side panel (default layout for desktop/laptop) */
.side-panel-R {
  width: 250px;
  background-color: var(--bg5);
  padding: 10px;
  overflow-y: auto;
  flex-shrink: 0;
}

/* Toggle button for mobile menu */
.mobile-menu-icon {
  display: none;
  cursor: pointer;
  padding: 10px 10px 10px 15px; 
  transition: 0.3s ease-in-out, 0.5s ease-in-out;
}

.mobile-menu-icon.active {
  background-color: var(--bg3);
  color: white; 
  transform: scale(1.1); 
  clip-path: polygon(0 50%, 35% 0, 100% 0, 100% 100%, 35% 100%);
}

.toggle-icon {
  position: absolute;
  top: 10px;
  right: -30px;
  cursor: pointer;
  background-color: var(--head-foot);
  color: white;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

/* Panel item styles */
.menu-panel {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.menu-panel i {
  margin-right: 10px;
}

.menu-panel:hover {
  background-color: #f4f4f4;
}
 
/*.menu-panel.selected:hover {
  background-color: var(--bg3);
} */

.menu-panel.selected:hover,
.menu-panel.selected {
  /* background-color: var(--bg3); */
  color: var(--btn);
  font-weight: bold;
  transform: scale(1.1); 
}

/* Submenu indentation */
.submenu {
  padding-left: 15px;
}

.error-message {
  color: black; 
  background-color: #ffe6e6;
  border: 1px solid red;
  padding: 10px; 
  margin: 20px; 
  border-radius: 5px;
  font-size: 16px; 
  display: flex; 
  align-items: center;
  
  i {
    margin-right: 10px;
  }
}


/* Responsive layout for tablet screens and below */
@media (max-width: 1024px) {
  .dashboard-panels-wrapper {
    flex-direction: column;
  }

  .side-panel-R {
    width: 100%;
    height: auto;
    order: 1; 
    padding-bottom: 10px;
  }

  .content-panel {
    order: 2;
  }
}

/* Mobile adjustments */
@media (max-width: 769px) {
  .side-panel-L {
    width: 100%;
    position: relative;
  }

  .mobile-menu-icon {
    display: block;
  }

  .side-panel-L.open {
    display: none;
  }

  .toggle-icon {
    display: block;
  }
}

/* Desktop and larger screens (keep panels side by side) */
@media (min-width: 769px) {
  body {
    font-size: 70%;
  }
  .dashboard-panels-wrapper {
    display: flex;
    flex-direction: row;
  }

  .side-panel-R {
    width: 250px;
    flex-shrink: 0;
    order: 2;
  }

  .content-panel {
    order: 1;
    margin-right: 10px; 
  }
}

@media (max-width: 769px) {
  body {
    font-size: 70%;
  }
  .side-panel-L {
    display: none;
    position: fixed;
    left: 0;
    top: 82px;
    width: 200px;
    height: calc(100vh - 60px);
    background-color: var(--bg5);
    opacity: 95%;
    z-index: 1000;
    transform: translateX(-0%);
    transition: 0.3s ease-in-out;
  }

  .side-panel-L.open {
    display: block;
    transform: translateX(0); 
  }

  .content-panel {
    position: relative;
    z-index: 1;
  }

  .mobile-menu-icon {
    display: flex;
  }  
}

@media (max-width: 426px) {
  body {
    font-size: 70%;
  }
}