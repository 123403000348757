.web-filter {

  .urlBlock-form {
    padding: 20px;
  }
  .new-url-form {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    gap: 5px;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .url-input {
    padding: 10px;
    width: 300px;
  }
  
  .confirm-btn, .cancel-btn {
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .add-url-btn {
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .del-btn {
    background-color: transparent;
    border-color: var(--del-btn);
    color: var(--del-btn);
    padding: 6px 20px;
    cursor: pointer;
    
    &:hover {
      border-color: var(--del-hov);
      color: var(--del-hov);
    }
  }

  @media (max-width: 426px) {
    .new-url-form {
      justify-content: center;
      align-items: center;

      input {
        width: 100%;
      }
    }
  }
}
  