.faculty-record {
  .faculty-rows {
    border-radius: 5px;
    max-height: 400px;
    overflow: hidden;
    overflow-x: auto;
    overflow-y: auto;
    
    .faculty-item {
      /* border: 1px solid #ccc; */
      background-color: #f3f3f3;
      margin: 10px 0;
      padding: 10px;
      overflow: hidden;
      overflow-x: auto;
      overflow-y: auto;
      text-align: center;
      align-items: center;
    }
  }

  .faculty-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .faculty-details {
    padding: 0 5px;
    display: flex;
    
    ul {
      display: flex;
      flex-direction: column;
      align-items: start;
      list-style-type: none;
      padding: 0;
      width: fit-content;
      
      li {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

  .add-faculty-form {
    padding: 20px;
    
    .faculty-form-inner {
      margin: 0 auto;
      max-width: 500px;
      text-align: center;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .user-form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 0;
        border-bottom: 1px solid #ddd;
      }

      .reg-div {
        display: flex ;
        margin-top: 10px;
        gap: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }

  .edit-faculty-form {
    .edit-faculty-form-inner {
      margin: 0 auto;
      max-width: 500px;
      text-align: center;
      align-items: center;
      justify-content: center;
      gap: 10px;
      
      .user-form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 0;
        border-bottom: 1px solid #ddd;
      }

      .reg-div {
        display: flex ;
        margin-top: 10px;
        gap: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }

  .gen-report {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .faculty-table {
    overflow: hidden;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 200px;
  }

  .rfid-list {
    display: flex;
    flex-direction: column;
    gap: 5px;

    /* rfid-item {
      display: flex;
      align-items: center;
      gap: 5px;
    } */
    .available-list {
      overflow: hidden;
      overflow-y: auto;
      overflow-x: auto;
      white-space: nowrap;

      .rfid-item {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        max-width: fit-content;
        text-align: center;
        justify-content: start;
        align-items: center;
        gap: 5px;
        margin-bottom: 10px;
      }

      .rfid-name {
        margin-right: 10px;
        font-weight: bold;
        min-width: 100px;
      }
    }
  }
}
  