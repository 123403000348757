.computer-controls {
  .dash-container {
    margin: 0 auto;
    width: 100%; 
    box-sizing: border-box; 
  }

  .stream-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .stream-row {
      display: flex;
      flex-wrap: wrap; 
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .stream-btn {
      display: flex;
      gap: 10px;
    }

    .stream-token {
      position: relative; 
      padding: 10px; 
      font-size: 14px;
      max-width: fit-content;
      text-align: center;
      color: var(--btn);
      font-size: 200%;
      font-weight: bold;
      text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
      
      span {
        font-weight: normal;
        position: absolute; 
        top: 0;
        left: 0;
        right: 0;
        color: var(--sub-txt);
        font-size: 8px;
      }
    }

    .view-stream-row {
      display: flex;
      flex-wrap: wrap; 
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }

    @media screen and (max-width: 426px) {
      .stream-container {
        display: block; 
      }
    }
  }

  .cont-divider {
    margin: 20px 0;
    height: 3px;
    background-color: var(--btn);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    border-radius: 2px;
  }

  .controls-row {
    margin-top: 3rem;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    text-align: center; 
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    .block-input-div,
    .set-admin-div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }


  .computer-list {
    padding: 20px;

    .checkbox-container {
      display: flex;
      flex-wrap: wrap; 
      align-items: center;
      margin-bottom: 10px;
      justify-content: center;
      gap: 10px;
      margin: 5px;
      padding-bottom: 20px;
    }

    .pcs-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 10px;
    }

    .pcs-table {
      display: none; 
      border-collapse: collapse; 
      overflow: hidden;
      overflow-x: auto;
      overflow-y: auto;
      
      table {
        width: 100%;
      }
      
      th, td {
        padding: 2px;
        text-align: center; 
        border: 1px solid #ddd; 
      }

      th {
        background-color: #f2f2f2;
      }

      .action {
        display: flex;
        justify-content: center; 
      }
    }

    .pc-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      width: 200px;
      background-color: #e9ecef;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.2s;
      gap: 5px;

      &.isAdmin {
        background-color: var(--bg4);
      }

      &.checked {
        background-color: var(--bg6); 
      }
    }

    .pc-icon {
      font-size: 24px; 
      margin-right: 10px; 
    }

    .pc-name {
      flex-grow: 1;
    }

    .checkbox-cell {
      display: flex;
      align-items: center; 
    }

    .hidden-checkbox {
      display: none;
    }

    .action {
      margin-left: 10px;
    }

    /* Toggle Switch Styles */
    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 56px; 
      height: 27.5px; 

      input {
        opacity: 0; 
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s; 
        border-radius: 34px; 

        &::before {
          position: absolute;
          content: "";
          height: 20px; 
          width: 20px; 
          left: 4px; 
          bottom: 4px; 
          background-color: white; 
          transition: 0.4s; 
          border-radius: 50%; 
        }
      }

      input:checked + .slider {
        background-color: #2196F3; 
      }

      input:checked + .slider::before {
        transform: translateX(26px); 
      }
    }

    /* Responsive styles */
    @media (max-width: 426px) {
      .pcs-grid {
        display: none; 
      }

      .pcs-table {
        display: block; 
      }
      
      .pcs-table th, .pcs-table td {
        width: 33.33%; 
      }

      .pcs-table th {
        font-weight: bold;
      }

      .pcs-table td {
        padding: 5px; 
      }
    }
  }
}
